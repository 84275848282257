const crypto = require("crypto");
const superagent = require("superagent");

//微信接口错误信息
const error = {
    "-1":"系统繁忙，请稍后再试。",
    "0":"请求成功",
    "40001":"AppSecret错误",
    "40002":"请确保grant_type为client_credential",
    "40164":"调用接口的IP不在白名单内"
};


let base={};

//SHA1加密
base.sha1 = code=>crypto.createHash("sha1").update(code).digest("hex");
//生成随机字符串
base.noncestr = _=>Math.random().toString(36).substr(2, 15);
//生成秒级时间戳
base.timestamp = _=>parseInt(new Date().getTime() / 1000);
//生成签名
base.signature = args=>{
    const raw = `jsapi_ticket=${args.jsapi_ticket}&noncestr=${args.noncestr}&timestamp=${args.timestamp}&url=${args.url}`;
    console.log(raw);
    const sha1 = this.sha1(raw);
    console.log(sha1);
    return sha1;
};
//微信接口 获取微信接口访问凭证 access_token
base.token = async (appid, secret)=>{
    const url = `https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=${appid}&secret=${secret}`;
    return this.httpGet(url);
};
//微信接口 获取授权页ticket
base.getTicket = (access_token, type="jsapi")=>{
    const url = `https://api.weixin.qq.com/cgi-bin/ticket/getticket?access_token=${access_token}&type=${type}`;
    return this.httpGet(url);
};
//网页授权 获取临时凭证code
base.authorize = (appid, redirect_uri, state="STATE", scope="snsapi_userinfo")=>{
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
    return url;
};
//网页授权 根据code换取access_token。
base.accessToken =  (appid, secret, code)=>{
    const url = `https://api.weixin.qq.com/sns/oauth2/access_token?appid=${appid}&secret=${secret}&code=${code}&grant_type=authorization_code`;
    return this.httpGet(url);
};
//网页授权 access_token超时刷新
base.refreshToken = (appid, refresh_token)=>{
    const url = `https://api.weixin.qq.com/sns/oauth2/refresh_token?appid=${appid}&grant_type=refresh_token&refresh_token=${refresh_token}`;
    return this.httpGet(url);
};
//网页授权 获取微信用户信息
base.userinfo =  (access_token, openid, lang="zh_CN")=>{
    const url = `https://api.weixin.qq.com/sns/userinfo?access_token=${access_token}&openid=${openid}&lang=${lang}`;
    return this.httpGet(url);
};

//CURL发送HTTP协议GET请求
base.httpGet = async url=>{
    console.log(url);
    const response = await superagent.get(url);
    const result = JSON.parse(response.text);
    //console.log(result);
    return result;
};

export default base;