<template>
  <div id="app">
	  <div class="userInfo" v-if="userInfo.nickname">
		  <div class='avatar'>
			  <img @click="toDeail()" :src="userInfo.headimgurl">
		  </div>
		  <div class="name">{{userInfo.nickname}}</div>
	  </div>
	  
	  <div class="search" v-if="uchurList.length>0">
		  <input type="text" placeholder="ئۇچۇر مەزمۇنىنى كىرگۈزۈڭ"/>
	  </div>
	  
	  <div class="category" v-if="uchurList.length>0">
		  <div class="cItem">رايون</div>
		  <div class="cItem">كەسىپ</div>
		  <div class="cItem">مائاش</div>
		  <div class="cItem">تەمىنات</div>
	  </div>
    
	
	<div class="uchur_list">
		<div class="item" v-for='(item,index) in uchurList' @click="toDeail(item.id)">
			<div class="name">
				<span>{{item.title}}</span>
			</div>
			<div class="dis">{{item.dis}}</div>
			<div class="money">{{item.money}}</div>
			<div class="tag" v-if="item.teminat">
				<span v-for='(v,i) in item.teminat'>{{v}}</span>
			</div>
		</div>
	</div>
	<div style="height:70px"/>
	<Footer  v-if="uchurList.length>0"/>
  </div>
</template>
<script src="/assets/wechat.js"></script>  
<script>

import {index,uchur} from '@/api/admin/login'
import { wechat } from '@/assets/wechat.js'
import Footer from '@/components/homeFooter.vue'
export default {
  name: 'index',
  components: {
    Footer
  },
  data() {
  	return {
		userInfo:{},
		uchurList:[],
		page:{}
  	}
  },
  mounted(){
      const env = process.env.NODE_ENV;
	  const appId = 'wxf135ab5476a2a77f'
      if(env === "production"){
          if(!this.isWechat()){
              console.log("请在微信中打开");
              return;
          }
          const code = this.$route.query.code;
          //console.log(code, !code, !!code, code===undefined);
          if(code === undefined){
              //const redirectUrl = process.env.VUE_APP_URL;
              const redirectUrl = window.location.href;//当前地址
              this.auth(appId, redirectUrl);
          }else{
              //微信授权获取用户信息
              index({code:code}).then(res=>{
                  const ret = res.data;
                  if(res.status == 200){
					  this.userInfo=ret
                      return;
                  }
              }).catch(err=>{
                  console.log(err);
              });
          }
      }
      this.env = env;
	  this.homeUchurApi()
  },
  methods:{    //微信浏览器判断
      isWechat(){
          const ua = window.navigator.userAgent.toLowerCase();
          return ua.indexOf('micromessenger') !== -1;
      },
      //微信授权
      auth(appId, redirectUrl, state="STATE"){
          const redirectUri = encodeURIComponent(redirectUrl);
          let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
          window.location.href = url;
      },
	  toDeail (e) {
		  console.log(e)
	     this.$router.push({path: '/detail', query: {id:e}})
	   },
	   
	   homeUchurApi(){
		   uchur().then(res=>{
			   this.uchurList=res.data.data
			   this.page = {
				   total:res.data.total,
				   last_page:res.data.last_page,
				   current_page:res.data.current_page
			   }
		   }).catch(err=>{
                  console.log(err);
              });
	   }
  }
}
</script>

<style>
	.search {
		overflow: hidden;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 10px 10px;
	}
	.search input {
		outline: none;
		height: 40px;
		line-height: 40px;
		border:none;
		font-family: 'Alkatip Basma Tom';
		background-color: #F8F8F8;
		border-radius: 5px;
		font-size: 14px;
		display: block;
		padding: 0 20px;
		width: 100%;
	}
	.userInfo {
		overflow: hidden;
	}
	.userInfo .avatar {
		overflow: hidden;
		border-radius: 50%;
		width: 100px;
		height: 100px;
	}
	.userInfo .avatar img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}
.uchur_list {
	overflow: hidden;
}
.uchur_list .item {
	padding: 10px;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 0px 0px 20px rgba(175,175,175,.2);
	margin: 10px;
	border-radius: 10px;
}
.uchur_list .item .name {
	overflow: hidden;
	color:#000;
	height: 30px;
	line-height: 30px;
}
.uchur_list .item .dis {
	color:#999;
	font-size: 12px;
	height: 25px;
	line-height: 25px;
}
.uchur_list .item .money {
	overflow: hidden;
	color:#FF5566;
	height: 30px;
	line-height: 30px;
}
.uchur_list .item:nth-last-child(1){
	border-bottom: 0;
}
.uchur_list .item em {
	color:#ff5566;
	font-style: normal;
	font-size: 14px;
}
.uchur_list .item:active {
	background-color: #f8f8f8;
}
.uchur_list .item .tag {
	overflow: hidden;
	display: flex;
	justify-content: start;
	align-items: center;
}
.uchur_list .item .tag span {
	font-size: 10px;
	background-color: #F8F8F8;
	display: block;
	margin-left: 10px;
	padding: 4px 6px;
	border-radius: 4px;
}
.uchur_list .item .tag span:nth-child(1){
	background-color: #409eff20;
	color:#409eff
}
.uchur_list .item .tag span:nth-child(2){
	background-color: #e6a23c20;
	color:#e6a23c
}
.uchur_list .item .tag span:nth-child(3){
	background-color: #f56c6c20;
	color:#f56c6c
}
.uchur_list .item .tag span:nth-child(4){
	background-color: #67c23a20;
	color:#67c23a
}

.category {
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	background-color: #FFFFFF;
	position: sticky;
	right: 0px;
	left: 0px;
	top: 0px;
}
.category .cItem {
	width: 50%;
	display: flex;justify-content: center;align-items: center;
}
</style>
