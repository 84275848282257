<template>
	<div id="footer" class="weli">
		<div class="footerItem" v-for="(item,i) in footerList">
			<div class="icon">H</div>
			<span>{{item.title}}</span>
		</div>
	</div>
</template>

<script>
	export default {
	  name: 'footer',
	  components: {
	    
	  },
	  data() {
	    return {
			footerList:[
				{id:1,title:'باش بەت'},
				{id:3,title:'ئۇچۇر يوللاش'},
				{id:5,title:'مېنىڭ'},
			]
	    }
	  },
	
	
	  watch: {
	    
	  },
	
	  methods: {
	
	},
	}
</script>

<style>
	.weli {
		overflow: hidden;
		position: fixed;
		bottom: 0px;
		right: 0px;
		left: 0px;
		background-color: #fff;
		height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.weli .footerItem {
		width: 50%;
		text-align: center;
	}
</style>
